import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton as Button,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CSpinner,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import 'moment/locale/tr'
import ReactPaginate from 'react-paginate'
import api from 'src/api'
import CIcon from '@coreui/icons-react'
import { cilCheckAlt, cilPencil, cilTrash, cilX } from '@coreui/icons'

moment.locale('tr')

function All() {
  const [contents, setContents] = useState([])
  const [content, setContent] = useState({})
  const [count, setCount] = useState(0)
  const [skip, setSkip] = useState(0)
  const [removeVisible, setRemoveVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  async function fetchContents(skip) {
    const get = await api.content.all(`?status=true&limit=20&skip=${skip}`)

    if (get.contents) {
      setContents(get.contents)
      setCount(get.count)
    }
  }

  async function removeContent() {
    // remove
    setLoading(true)

    const update = await api.content.update({ id: content.id, status: false, publish: false })

    if (update.success) {
      setLoading(false)
      setRemoveVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchContents(skip)
    } else {
      setLoading(false)
      setRemoveVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  useEffect(() => {
    fetchContents(skip)
    // eslint-disable-next-line
  }, [])

  async function updateContent(id, published) {
    const update = await api.content.update({ id, publish: !published })

    if (update.success) {
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      fetchContents(skip)
    } else {
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      fetchContents(skip)
    }
  }

  const pageCount = Math.ceil(count / 20)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 20) % count

    fetchContents(newOffset)
    setSkip(newOffset)
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Haberler</strong>
          </CCardHeader>
          <CCardBody>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Başlık</th>
                  <th>Kategori</th>
                  <th>Okunma</th>
                  <th>Ekleyen</th>
                  <th>Eklenme/Düzenlenme T.</th>
                  <th>#</th>
                </tr>
              </thead>

              <tbody>
                {contents.length > 0 &&
                  contents.map((content, i) => (
                    <tr key={i}>
                      <td>{content?.id}</td>
                      <td>{content?.title}</td>
                      <td>{content?.category?.label}</td>
                      <td>{content?.readCount}</td>
                      <td>{content?.author?.fullname}</td>
                      <td>
                        <span>
                          {moment(content?.createdAt).format('DD MMM YYYY, HH:mm')} <br />{' '}
                          {moment(content?.updatedAt).format('DD MMM YYYY, HH:mm')}
                        </span>
                      </td>
                      <td>
                        <>
                          <a
                            className="btn btn-secondary btn-sm text-white"
                            href={`/contents/update/${content?.id}`}
                          >
                            <CIcon icon={cilPencil} />
                          </a>
                          <Button
                            color="warning"
                            className="ms-1 text-white btn-sm"
                            onClick={() => {
                              setContent({
                                id: content?.id,
                                title: content?.title,
                              })
                              setRemoveVisible(true)
                            }}
                          >
                            <CIcon icon={cilTrash} />
                          </Button>
                          <Button
                            color={content?.publish ? 'success' : 'danger'}
                            className="ms-1 text-white btn-sm"
                            onClick={() => {
                              updateContent(content?.id, content?.publish)
                            }}
                          >
                            {content?.publish ? (
                              <CIcon icon={cilCheckAlt} />
                            ) : (
                              <CIcon icon={cilX} />
                            )}
                          </Button>
                        </>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              previousLabel="<"
              onPageChange={handlePageClick}
              pageRangeDisplayed={20}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
          </CCardBody>
        </CCard>
      </CCol>

      <Modal visible={removeVisible} onClose={() => setRemoveVisible(false)}>
        <ModalHeader onClose={() => setRemoveVisible(false)}>
          <ModalTitle>Haberi Sil</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Haberi silmek istediğinize emin misiniz?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => removeContent()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Sil
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setRemoveVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default All
