import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable as Table,
  CTableBody as TBody,
  CTableDataCell as Td,
  CTableHead as THead,
  CTableHeaderCell as Th,
  CTableRow as Tr,
  CButton as Button,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CFormInput as Input,
  CSpinner,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import api from 'src/api'

function RemovedCategories() {
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState({})
  const [updateVisible, setUpdateVisible] = useState(false)
  const [removeVisible, setRemoveVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  async function fetchCategories() {
    const get = await api.category.all(false)

    if (get) {
      setCategories(get)
    }
  }

  async function updateCategory() {
    // update
    setLoading(true)

    const update = await api.category.update(category)

    if (update.success) {
      setLoading(false)
      setUpdateVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchCategories()
    } else {
      setLoading(false)
      setUpdateVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  async function removeCategory() {
    // remove
    setLoading(true)

    const update = await api.category.update({ id: category.id, status: true })

    if (update.success) {
      setLoading(false)
      setRemoveVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchCategories()
    } else {
      setLoading(false)
      setRemoveVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Silinen Kategoriler</strong>
          </CCardHeader>
          <CCardBody>
            <Table>
              <THead>
                <Tr>
                  <Th scope="col">ID</Th>
                  <Th scope="col">Adı</Th>
                  <Th scope="col">Ekleyen</Th>
                  <Th scope="col">#</Th>
                </Tr>
              </THead>
              <TBody>
                {categories.length > 0 &&
                  categories.map((category, i) => (
                    <Tr key={i}>
                      <Th scope="row">{category.id}</Th>
                      <Td>{category.name}</Td>
                      <Td>{category.author}</Td>
                      <Td>
                        <Button
                          color="secondary"
                          onClick={() => {
                            setCategory({ id: category.id, name: category.name })
                            setUpdateVisible(true)
                          }}
                        >
                          Düzenle
                        </Button>
                        <Button
                          color="warning"
                          className="ms-1"
                          onClick={() => {
                            setCategory({ id: category.id, name: category.name })
                            setRemoveVisible(true)
                          }}
                        >
                          Geri Al
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>
          </CCardBody>
        </CCard>
      </CCol>

      <Modal visible={updateVisible} onClose={() => setUpdateVisible(false)}>
        <ModalHeader onClose={() => setUpdateVisible(false)}>
          <ModalTitle>Kategoriyi Düzenle</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form>
            <Input
              type="text"
              placeholder="Girin"
              required
              value={category?.name}
              onChange={(e) => setCategory({ ...category, name: e.target.value })}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => updateCategory()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Güncelle
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setUpdateVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <Modal visible={removeVisible} onClose={() => setRemoveVisible(false)}>
        <ModalHeader onClose={() => setRemoveVisible(false)}>
          <ModalTitle>Kategoriyi Geri Al</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Kategoriyi geri almak istediğinize emin misiniz?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => removeCategory()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Geri Al
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setRemoveVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default RemovedCategories
