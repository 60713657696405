import React, { useEffect, useState, useCallback } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
  CFormTextarea,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import api from 'src/api'
import { cilPlus, cilSearch, cilTrash, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { NewsImage } from 'src/utils'

function UpdateWriter() {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [file, setFile] = useState(null)
  const [photos, setPhotos] = useState([])

  const [search, setSearch] = useState('')
  const [isSearch, setIsSearch] = useState(false)

  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleGallery, setVisibleGallery] = useState(false)

  const [writer, setWriter] = useState({})

  const { id } = useParams('id')

  useEffect(() => {
    if (id) getWriter(id)
  }, [id])

  async function getWriter(id) {
    const writer = await api.writer.get(id)

    if (writer.success) {
      setWriter(writer)
    }
  }

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullname: writer.fullname || '',
      photo: writer.photo || '',
      email: writer.email || '',
      facebook: writer.facebook || '',
      instagram: writer.instagram || '',
      twitter: writer.twitter || '',
      youtube: writer.youtube || '',
      whatsapp: writer.whatsapp || '',
      telegram: writer.telegram || '',
      bio: writer.bio || '',
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string().required('Yazarın adını girmelisiniz.'),
      photo: Yup.string(),
      email: Yup.string(),
      facebook: Yup.string(),
      instagram: Yup.string(),
      twitter: Yup.string(),
      youtube: Yup.string(),
      whatsapp: Yup.string(),
      telegram: Yup.string(),
      bio: Yup.string(),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      const update = await api.writer.update({ id, ...values })

      if (update.success) {
        setLoading(false)
        toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        setTimeout(() => window.location.reload(), 2000)
      } else {
        setLoading(false)
        toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  useEffect(() => {
    fetchPhotos()
  }, [])

  function debounce(func, wait) {
    let timeout
    return function (...args) {
      const context = this
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        func.apply(context, args)
      }, wait)
    }
  }

  // eslint-disable-next-line
  const debounceOnchange = useCallback(debounce(onChange, 400), [])

  async function fetchPhotos() {
    const gallery = await api.photo.all(true)

    if (gallery) setPhotos(gallery)
  }

  async function onChange(value) {
    const search = await api.photo.search(value)

    if (search) {
      setPhotos(search)
      setIsSearch(true)
    }
  }

  function onCloseHandle() {
    setIsSearch(false)
    setSearch('')
    fetchPhotos()
  }

  async function handleSubmitPhoto() {
    setLoading(true)

    const data = new FormData()
    data.append('title', title)
    data.append('file', file)

    const add = await api.photo.add(data)

    if (add.success) {
      setLoading(false)
      setVisibleModal(false)
      fetchPhotos()
      setFieldValue('photo', add.photoId)
      toast.success(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    } else {
      setLoading(false)
      setVisibleModal(false)
      fetchPhotos()
      toast.error(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  return (
    <CRow>
      <CForm onSubmit={handleSubmit}>
        <CCol xs={12}>
          <CRow>
            <CCol xl={9} lg={7}>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Yazar Düzenle</strong>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={6}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="fullname">Adı</CFormLabel>
                        <CFormInput
                          type="text"
                          id="fullname"
                          placeholder="Girin"
                          onChange={handleChange}
                          value={values.fullname}
                        />

                        {errors.fullname && touched.fullname && (
                          <div>
                            <CFormFeedback invalid className="d-block">
                              {errors.fullname}
                            </CFormFeedback>
                          </div>
                        )}
                      </div>
                    </CCol>
                    <CCol md={6}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="email">Eposta Adresi</CFormLabel>
                        <CFormInput
                          type="text"
                          id="email"
                          placeholder="Girin"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </div>
                    </CCol>
                  </CRow>

                  <div className="mb-3">
                    <CFormLabel htmlFor="bio">Biyografi</CFormLabel>
                    <CFormTextarea
                      type="text"
                      id="bio"
                      placeholder="Girin"
                      onChange={handleChange}
                      value={values.bio}
                    />
                  </div>
                </CCardBody>
              </CCard>

              <CCard>
                <CCardHeader>
                  <strong>Sosyal Medya Hesapları</strong>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol md={4}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="facebook">Facebook</CFormLabel>
                        <CFormInput
                          type="text"
                          id="facebook"
                          placeholder="Girin"
                          onChange={handleChange}
                          value={values.facebook}
                        />
                      </div>
                    </CCol>
                    <CCol md={4}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="instagram">Instagram</CFormLabel>
                        <CFormInput
                          type="text"
                          id="instagram"
                          placeholder="Girin"
                          onChange={handleChange}
                          value={values.instagram}
                        />
                      </div>
                    </CCol>
                    <CCol md={4}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="twitter">Twitter</CFormLabel>
                        <CFormInput
                          type="text"
                          id="twitter"
                          placeholder="Girin"
                          onChange={handleChange}
                          value={values.twitter}
                        />
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol md={4}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="youtube">Youtube</CFormLabel>
                        <CFormInput
                          type="text"
                          id="youtube"
                          placeholder="Girin"
                          onChange={handleChange}
                          value={values.youtube}
                        />
                      </div>
                    </CCol>
                    <CCol md={4}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="whatsapp">WhatsApp</CFormLabel>
                        <CFormInput
                          type="text"
                          id="whatsapp"
                          placeholder="Girin"
                          onChange={handleChange}
                          value={values.whatsapp}
                        />
                      </div>
                    </CCol>
                    <CCol md={4}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="telegram">Telegram</CFormLabel>
                        <CFormInput
                          type="text"
                          id="telegram"
                          placeholder="Girin"
                          onChange={handleChange}
                          value={values.telegram}
                        />
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>

            <CCol xl={3} lg={5}>
              <CCard className="mb-4">
                <CCardBody>
                  <div className="d-flex flex-row align-items-center">
                    <CButton type="submit" disabled={loading}>
                      {loading && <CSpinner size="sm" className="me-2" />}
                      Kaydet
                    </CButton>
                  </div>
                </CCardBody>
              </CCard>

              <CCard>
                <CCardBody>
                  {values.photo && (
                    <div className="col-12 mb-3 gallery-photo">
                      <img
                        src={NewsImage(values.photo)}
                        className="rounded img-fluid img-thumbnail"
                        alt=""
                      />

                      <div className="photo-edit">
                        <CButton
                          onClick={() => {
                            setFieldValue('photo', '')
                          }}
                        >
                          <CIcon icon={cilTrash} />
                        </CButton>
                      </div>
                    </div>
                  )}

                  <CButton
                    color="warning"
                    className="w-100 text-white"
                    onClick={() => setVisibleGallery(true)}
                  >
                    Fotoğraf Seçin
                  </CButton>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CForm>

      <ToastContainer />

      <Modal
        visible={visibleGallery}
        onClose={() => setVisibleGallery(false)}
        size="xl"
        scrollable
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader onClose={() => setVisibleGallery(false)}>
          <ModalTitle className="w-100">
            <div className="d-flex flex-row align-items-center justify-content-between me-4">
              <strong className="text-nowrap">Fotoğraf Galerisi</strong>

              <div className="d-flex flex-row">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Fotoğraf ara"
                    className="form-control"
                    onChange={(e) => {
                      debounceOnchange(e.target.value)
                      setSearch(e.target.value)
                    }}
                    value={search}
                  />
                  <span className="input-group-text" id="basic-addon1">
                    <CIcon icon={cilSearch} />
                  </span>
                </div>

                {isSearch && (
                  <div
                    onClick={() => onCloseHandle()}
                    className="btn btn-info text-nowrap me-4 ms-2 text-white"
                  >
                    <CIcon icon={cilX} />
                  </div>
                )}
              </div>

              <div
                onClick={() => {
                  setVisibleModal(true)
                  setVisibleGallery(false)
                }}
                className="btn btn-info text-nowrap ms-2 text-white"
              >
                Ekle
              </div>
            </div>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {photos?.map((photo, i) => (
              <div className="col-2 mb-3 gallery-photo" key={i}>
                <img
                  src={NewsImage(photo.photoId)}
                  className="rounded img-fluid img-thumbnail"
                  alt=""
                />

                <div className="photo-edit">
                  <CButton
                    onClick={() => {
                      setVisibleGallery(false)
                      setFieldValue('photo', photo.photoId)
                    }}
                  >
                    <CIcon icon={cilPlus} />
                  </CButton>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <ModalHeader onClose={() => setVisibleModal(false)}>
          <ModalTitle>Görsel Ekle</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form>
            <input
              type="text"
              placeholder="Başlık girin"
              required
              className="form-control mb-3"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="file"
              className="form-control"
              required
              onChange={(e) => setFile(e.target.files[0])}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <CButton color="primary" onClick={handleSubmitPhoto}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Ekle
          </CButton>
          <CButton color="danger" className="ms-1" onClick={() => setVisibleModal(false)}>
            Vazgeç
          </CButton>
        </ModalFooter>
      </Modal>
    </CRow>
  )
}

export default UpdateWriter
