import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable as Table,
  CTableBody as TBody,
  CTableDataCell as Td,
  CTableHead as THead,
  CTableHeaderCell as Th,
  CTableRow as Tr,
  CButton as Button,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CSpinner,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import api from 'src/api'

function All() {
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({})
  const [removeVisible, setRemoveVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  async function fetchUsers() {
    const get = await api.writer.all()

    if (get) {
      setUsers(get)
    }
  }

  async function removeUser() {
    // remove
    setLoading(true)

    const update = await api.writer.update({ id: user.id, status: !user.status })

    if (update.success) {
      setLoading(false)
      setRemoveVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchUsers()
    } else {
      setLoading(false)
      setRemoveVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Yazarlar</strong>
          </CCardHeader>
          <CCardBody>
            <Table>
              <THead>
                <Tr>
                  <Th scope="col">ID</Th>
                  <Th scope="col">Adı</Th>
                  <Th scope="col">#</Th>
                </Tr>
              </THead>
              <TBody>
                {users.length > 0 &&
                  users
                    .filter((c) => c.username !== 'emrepirnaz')
                    .map((user, i) => (
                      <Tr key={i}>
                        <Th scope="row">{user.id}</Th>
                        <Td>{user.fullname}</Td>
                        <Td>
                          <a className="btn btn-secondary" href={`/writers/update/${user.id}`}>
                            Düzenle
                          </a>
                          <Button
                            color="warning"
                            className="ms-1"
                            onClick={() => {
                              setUser({
                                id: user.id,
                                fullname: user.title,
                                status: user.status,
                              })
                              setRemoveVisible(true)
                            }}
                          >
                            {user.status ? 'Sil' : 'Geri Al'}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
              </TBody>
            </Table>
          </CCardBody>
        </CCard>
      </CCol>

      <Modal visible={removeVisible} onClose={() => setRemoveVisible(false)}>
        <ModalHeader onClose={() => setRemoveVisible(false)}>
          <ModalTitle>Yazarı {user.status ? 'Sil' : 'Geri Al'}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Yazarı {user.status ? 'silmek' : 'geri almak'} istediğinize emin misiniz?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => removeUser()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            {user.status ? 'Sil' : 'Geri Al'}
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setRemoveVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default All
