import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  CButton as Button,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CFormInput as Input,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from 'src/api'
import CIcon from '@coreui/icons-react'
import { cilCheckAlt, cilLink, cilPen, cilSearch, cilX } from '@coreui/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

function Gallery() {
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleEditModal, setVisibleEditModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [photos, setPhotos] = useState([])
  const [photo, setPhoto] = useState({})
  const [search, setSearch] = useState('')
  const [isSearch, setIsSearch] = useState(false)

  const [title, setTitle] = useState('')
  const [file, setFile] = useState(null)

  // eslint-disable-next-line
  const debounceOnchange = useCallback(debounce(onChange, 400), [])

  async function handleSubmit() {
    setLoading(true)

    const data = new FormData()
    data.append('title', title)
    data.append('file', file)

    const add = await api.photo.add(data)

    if (add.success) {
      setLoading(false)
      setVisibleModal(false)
      fetchPhotos()
      toast.success(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    } else {
      setLoading(false)
      setVisibleModal(false)
      fetchPhotos()
      toast.error(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  async function handleEdit() {
    setLoading(true)

    const update = await api.photo.update({ id: photo.id, title })

    if (update.success) {
      setLoading(false)
      setVisibleEditModal(false)
      fetchPhotos()
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    } else {
      setLoading(false)
      setVisibleEditModal(false)
      fetchPhotos()
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  async function fetchPhotos() {
    const photos = await api.photo.all(true)

    if (photos) {
      setPhotos(photos)
    }
  }

  useEffect(() => {
    fetchPhotos()
  }, [])

  function debounce(func, wait) {
    let timeout
    return function (...args) {
      const context = this
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        func.apply(context, args)
      }, wait)
    }
  }

  async function onChange(value) {
    const search = await api.photo.search(value)

    if (search) {
      setPhotos(search)
      setIsSearch(true)
    }
  }

  function closeHandle() {
    setIsSearch(false)
    setSearch('')
    fetchPhotos()
  }

  function GalleryItem({ photoId, id, title }) {
    const [copied, setCopied] = useState(false)

    return (
      <div className="col-2 mb-3 gallery-photo">
        <img
          src={`https://cdn.neoxmedya.com/images/${photoId}.webp`}
          className="rounded img-fluid img-thumbnail"
          alt=""
        />

        <div className="photo-edit">
          <Button
            onClick={() => {
              setVisibleEditModal(true)
              setPhoto({ id, title })
              setTitle(title)
            }}
          >
            <CIcon icon={cilPen} />
          </Button>

          <CopyToClipboard
            text={`https://cdn.neoxmedya.com/images/${photoId}.webp`}
            onCopy={() => {
              setCopied(true)
              setTimeout(() => setCopied(false), 300)
            }}
          >
            {copied ? (
              <Button className="ms-2" color="success">
                <CIcon icon={cilCheckAlt} />
              </Button>
            ) : (
              <Button className="ms-2">
                <CIcon icon={cilLink} />
              </Button>
            )}
          </CopyToClipboard>
        </div>
      </div>
    )
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
              <strong>Galeri</strong>

              <div className="d-flex flex-row align-items-center">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Fotoğraf ara"
                    className="form-control"
                    onChange={(e) => {
                      debounceOnchange(e.target.value)
                      setSearch(e.target.value)
                    }}
                    value={search}
                  />
                  <span className="input-group-text" id="basic-addon1">
                    <CIcon icon={cilSearch} />
                  </span>
                </div>

                {isSearch && (
                  <Button
                    color="info"
                    onClick={() => closeHandle()}
                    className="text-nowrap me-4 ms-2 text-white"
                  >
                    <CIcon icon={cilX} />
                  </Button>
                )}

                <Button
                  color="secondary"
                  onClick={() => {
                    setVisibleModal(true)
                    setTitle('')
                  }}
                  className="text-nowrap ms-2"
                >
                  Yeni Ekle
                </Button>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <div className="row">
              {photos?.slice(0, 50).map((photo, i) => (
                <GalleryItem key={i} {...photo} />
              ))}
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <ModalHeader onClose={() => setVisibleModal(false)}>
          <ModalTitle>Görsel Ekle</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form>
            <Input
              type="text"
              placeholder="Başlık girin"
              required
              className="mb-3"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Input type="file" required onChange={(e) => setFile(e.target.files[0])} />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Ekle
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setVisibleModal(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <Modal visible={visibleEditModal} onClose={() => setVisibleEditModal(false)}>
        <ModalHeader onClose={() => setVisibleEditModal(false)}>
          <ModalTitle>Görsel Düzenle</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form>
            <Input
              type="text"
              placeholder="Girin"
              required
              className="mb-3"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleEdit}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Düzenle
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setVisibleEditModal(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default memo(Gallery)
