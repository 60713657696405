import axios from 'axios'

const URI = 'https://api.neoxmedya.com'
//const URI = 'http://localhost:3004'

const token =
  localStorage.getItem('auth-token') && localStorage.getItem('auth-token').replaceAll('"', '')

axios.defaults.headers.common = { Authorization: `Bearer ${token}` }

// eslint-disable-next-line
export default {
  tag: {
    add: (data) =>
      axios
        .post(`${URI}/tag/add`, data)
        .then((res) => res.data)
        .catch((err) => err),
    all: (status) =>
      axios
        .get(`${URI}/tag/all?status=${status}`)
        .then((res) => res.data)
        .catch((err) => err),
    get: (id) =>
      axios
        .get(`${URI}/tag/get/${id}`)
        .then((res) => res.data)
        .catch((err) => err),
    update: (data) =>
      axios
        .put(`${URI}/tag/update`, data)
        .then((res) => res.data)
        .catch((err) => err),
    search: (title) =>
      axios
        .get(`${URI}/tag/search/${title}`)
        .then((res) => res.data)
        .catch((err) => err),
  },

  category: {
    add: (data) =>
      axios
        .post(`${URI}/category/add`, data)
        .then((res) => res.data)
        .catch((err) => err),
    all: (status) =>
      axios
        .get(`${URI}/category/all?status=${status}`)
        .then((res) => res.data)
        .catch((err) => err),
    get: (id) =>
      axios
        .get(`${URI}/category/get/${id}`)
        .then((res) => res.data)
        .catch((err) => err),
    update: (data) =>
      axios
        .put(`${URI}/category/update`, data)
        .then((res) => res.data)
        .catch((err) => err),
    search: (title) =>
      axios
        .put(`${URI}/category/search/${title}`)
        .then((res) => res.data)
        .catch((err) => err),
  },

  content: {
    add: (data) =>
      axios
        .post(`${URI}/content/add`, data)
        .then((res) => res.data)
        .catch((err) => err),

    all: (params) =>
      axios
        .get(`${URI}/content/all${params}`)
        .then((res) => res.data)
        .catch((err) => err),

    iha: () =>
      axios
        .get(`${URI}/agency/all`)
        .then((res) => res.data)
        .catch((err) => err),

    get: (id) =>
      axios
        .get(`${URI}/content/get/${id}`)
        .then((res) => res.data)
        .catch((err) => err),

    update: (data) =>
      axios
        .put(`${URI}/content/update`, data)
        .then((res) => res.data)
        .catch((err) => err),
  },

  settings: {
    add: (data) =>
      axios
        .post(`${URI}/setting/add`, data)
        .then((res) => res.data)
        .catch((err) => err),
    get: (id) =>
      axios
        .get(`${URI}/setting/get/${id}`)
        .then((res) => res.data)
        .catch((err) => err),
    update: (data) =>
      axios
        .put(`${URI}/setting/update`, data)
        .then((res) => res.data)
        .catch((err) => err),
  },

  advert: {
    add: (data) =>
      axios
        .post(`${URI}/advert/add`, data)
        .then((res) => res.data)
        .catch((err) => err),
    all: (status) =>
      axios
        .get(`${URI}/advert/all?status=${status}`)
        .then((res) => res.data)
        .catch((err) => err),
    get: (id) =>
      axios
        .get(`${URI}/advert/get/${id}`)
        .then((res) => res.data)
        .catch((err) => err),
    update: (data) =>
      axios
        .put(`${URI}/advert/update`, data)
        .then((res) => res.data)
        .catch((err) => err),
  },

  pages: {
    add: (data) =>
      axios
        .post(`${URI}/page/add`, data)
        .then((res) => res.data)
        .catch((err) => err),
    all: (status) =>
      axios
        .get(`${URI}/page/all?status=${status}`)
        .then((res) => res.data)
        .catch((err) => err),
    get: (id) =>
      axios
        .get(`${URI}/page/get/${id}`)
        .then((res) => res.data)
        .catch((err) => err),
    update: (data) =>
      axios
        .put(`${URI}/page/update`, data)
        .then((res) => res.data)
        .catch((err) => err),
  },

  photo: {
    add: (data) =>
      axios
        .post(`${URI}/photo/add`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((res) => res.data)
        .catch((err) => err),

    all: (status) =>
      axios
        .get(`${URI}/photo/all?status=${status}`)
        .then((res) => res.data)
        .catch((err) => err),

    update: (data) =>
      axios
        .put(`${URI}/photo/update`, data)
        .then((res) => res.data)
        .catch((err) => err),

    search: (title) =>
      axios
        .get(`${URI}/photo/search/${title}`)
        .then((res) => res.data)
        .catch((err) => err),
  },

  user: {
    add: (data) =>
      axios
        .post(`${URI}/user/add`, data)
        .then((res) => res.data)
        .catch((err) => err),

    all: () =>
      axios
        .get(`${URI}/user/all`)
        .then((res) => res.data)
        .catch((err) => err),

    update: (data) =>
      axios
        .put(`${URI}/user/update`, data)
        .then((res) => res.data)
        .catch((err) => err),

    get: (id) =>
      axios
        .get(`${URI}/user/get/${id}`)
        .then((res) => res.data)
        .catch((err) => err),

    login: (data) =>
      axios
        .post(`${URI}/user/login`, data)
        .then((res) => res.data)
        .catch((err) => err),
  },
  writer: {
    add: (data) =>
      axios
        .post(`${URI}/writer/add`, data)
        .then((res) => res.data)
        .catch((err) => err),
    all: () =>
      axios
        .get(`${URI}/writer/all`)
        .then((res) => res.data)
        .catch((err) => err),
    update: (data) =>
      axios
        .put(`${URI}/writer/update`, data)
        .then((res) => res.data)
        .catch((err) => err),
    get: (id) =>
      axios
        .get(`${URI}/writer/get/${id}`)
        .then((res) => res.data)
        .catch((err) => err),
  },
}
