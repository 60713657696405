import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import api from 'src/api'

function AddTag() {
  const [loading, setLoading] = useState(false)

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Etiket adı girmelisiniz.'),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      const add = await api.tag.add(values)

      if (add.success) {
        setLoading(false)
        toast.success(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        setTimeout(() => window.location.reload(), 2000)
      } else {
        setLoading(false)
        toast.error(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Etiketler</strong>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={handleSubmit}>
              <div className="mb-3">
                <CFormLabel htmlFor="name">Etiket adı</CFormLabel>
                <CFormInput
                  type="text"
                  id="name"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.name}
                />

                {errors.name && touched.name && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.name}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="col-auto">
                <CButton type="submit" className="mb-3" disabled={loading}>
                  {loading && <CSpinner size="sm" className="me-2" />}
                  Kaydet
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>

      <ToastContainer />
    </CRow>
  )
}

export default AddTag
