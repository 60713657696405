import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import api from 'src/api'
import { useParams } from 'react-router-dom'

function UpdateUser() {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})

  const { id } = useParams('id')

  useEffect(() => {
    if (id) {
      if (id === 1 || id === '1') window.location.href = '/admins/all'

      getUser(id)
    }
  }, [id])

  async function getUser(id) {
    const user = await api.user.get(id)

    if (user.success) {
      setUser(user?.user[0])
    }
  }

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullname: user.fullname || '',
      username: user.username || '',
      newPassword: '',
      email: user.email || '',
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string().required('Yöneticinin adını girmelisiniz.'),
      username: Yup.string().required('Yöneticinin kullanıcı adını girmelisiniz.'),
      email: Yup.string().required('Eposta adresi girmelisiniz.'),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      const update = await api.user.update({ id, ...values })

      if (update.success) {
        setLoading(false)
        toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        setTimeout(() => window.location.reload(), 2000)
      } else {
        setLoading(false)
        toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Yönetici Düzenle</strong>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={handleSubmit}>
              <div className="mb-3">
                <CFormLabel htmlFor="fullname">Adı</CFormLabel>
                <CFormInput
                  type="text"
                  id="fullname"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.fullname}
                />

                {errors.fullname && touched.fullname && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.fullname}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="username">Kullanıcı adı</CFormLabel>
                <CFormInput
                  type="text"
                  id="username"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.username}
                  disabled
                />

                {errors.username && touched.username && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.username}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="email">Eposta adresi</CFormLabel>
                <CFormInput
                  type="text"
                  id="email"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.email}
                  disabled
                />

                {errors.email && touched.email && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.email}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="newPassword">Yeni Şifre</CFormLabel>
                <CFormInput
                  type="password"
                  id="newPassword"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.newPassword}
                />

                {errors.newPassword && touched.newPassword && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.newPassword}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="col-auto">
                <CButton type="submit" className="mb-3" disabled={loading}>
                  {loading && <CSpinner size="sm" className="me-2" />}
                  Kaydet
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>

      <ToastContainer />
    </CRow>
  )
}

export default UpdateUser
