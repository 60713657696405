import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import CIcon from '@coreui/icons-react'
import { cilPen } from '@coreui/icons'
import { useParams } from 'react-router-dom'

import api from 'src/api'
import { permalink } from 'src/utils'

function UodatePage() {
  const [loading, setLoading] = useState(false)
  const [lockSlug, setLockSlug] = useState(true)
  const [page, setPage] = useState({})

  const { id } = useParams('id')

  useEffect(() => {
    if (id) {
      getPage(id)
    }
  }, [id])

  async function getPage(id) {
    const page = await api.pages.get(id)

    if (page.success) {
      setPage(page)
    }
  }

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: page?.title || '',
      slug: page?.slug || '',
      content: page?.content || '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Başlık belirtmelisiniz.'),
      slug: Yup.string(),
      content: Yup.string().required('İçerik girmelisiniz.'),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      const update = await api.pages.update({
        id,
        ...values,
        slug: values.slug ? values.slug : permalink(values.title),
      })

      if (update.success) {
        setLoading(false)
        toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        setTimeout(() => window.location.reload(), 2000)
      } else {
        setLoading(false)
        toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Sayfa Düzenle</strong>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={handleSubmit}>
              <div className="mb-3">
                <CFormLabel htmlFor="title">Başlık</CFormLabel>
                <CFormInput
                  type="text"
                  id="title"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.title}
                />

                {errors.title && touched.title && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.title}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="slug">Slug</CFormLabel>
                <div className="d-flex">
                  <CFormInput
                    type="text"
                    id="slug"
                    placeholder="Girin"
                    onChange={handleChange}
                    value={permalink(values.slug) || permalink(values.title)}
                    disabled={lockSlug}
                  />
                  <CButton
                    color="info"
                    className="text-white ms-1"
                    onClick={() => setLockSlug(!lockSlug)}
                  >
                    <CIcon icon={cilPen} />
                  </CButton>
                </div>

                <small className="form-text d-block">
                  Slug URL&apos;e verilen isimdir. Örneğin &quot;Hakkımızda&quot; başlığının slug
                  değeri &quot;hakkimizda&quot; olabilir. Buraya belirtilen değer sitede erişilecek
                  değerdir. Örneğin /hakkimizda
                </small>

                {errors.slug && touched.slug && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.slug}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="content">İçerik</CFormLabel>
                <CKEditor
                  editor={ClassicEditor}
                  data={values.content}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setFieldValue('content', data)
                  }}
                />

                {errors.content && touched.content && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.content}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="col-auto">
                <CButton type="submit" className="mb-3" disabled={loading}>
                  {loading && <CSpinner size="sm" className="me-2" />}
                  Kaydet
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>

      <ToastContainer />
    </CRow>
  )
}

export default UodatePage
