import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilAccountLogout } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import Avatar from 'react-avatar'
import decode from 'jwt-decode'

export default function AppHeaderDropdown() {
  const [user, setUser] = useState({ fullname: '' })

  function logout() {
    localStorage.removeItem('auth-token')
    window.location.reload()
  }

  useEffect(() => {
    const token =
      localStorage.getItem('auth-token') && localStorage.getItem('auth-token').replaceAll('"', '')

    const user = decode(token)

    setUser(user)
  }, [])

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar>
          <Avatar size="32" name={user.fullname} round />
        </CAvatar>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Hesap</CDropdownHeader>
        <CDropdownItem href="#" onClick={() => logout()}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Çıkış Yap
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}
