import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ProtectedRoute = (props) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  function checkUserToken() {
    const userToken = localStorage.getItem('auth-token')
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false)
      return navigate('/')
    }

    setIsLoggedIn(true)
  }

  useEffect(() => {
    checkUserToken()
    // eslint-disable-next-line
  }, [isLoggedIn])

  return <>{isLoggedIn ? props.children : null}</>
}

const PublicRoute = (props) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  function checkUserToken() {
    const userToken = localStorage.getItem('auth-token')

    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false)
      return navigate('/')
    } else {
      navigate('/contents/all')
    }

    setIsLoggedIn(true)
  }

  useEffect(() => {
    checkUserToken()
    // eslint-disable-next-line
  }, [isLoggedIn])

  return <>{!isLoggedIn ? props.children : null}</>
}

export { ProtectedRoute, PublicRoute }
