import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable as Table,
  CTableBody as TBody,
  CTableDataCell as Td,
  CTableHead as THead,
  CTableHeaderCell as Th,
  CTableRow as Tr,
  CButton as Button,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CSpinner,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import api from 'src/api'

function All() {
  const [pages, setPages] = useState([])
  const [page, setPage] = useState({})
  const [removeVisible, setRemoveVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  async function fetchPages() {
    const get = await api.pages.all(true)

    if (get) {
      setPages(get)
    }
  }

  async function removePage() {
    // remove
    setLoading(true)

    const update = await api.pages.update({ id: page.id, status: false })

    if (update.success) {
      setLoading(false)
      setRemoveVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchPages()
    } else {
      setLoading(false)
      setRemoveVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  useEffect(() => {
    fetchPages()
  }, [])

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Sayfalar</strong>
          </CCardHeader>
          <CCardBody>
            <Table>
              <THead>
                <Tr>
                  <Th scope="col">ID</Th>
                  <Th scope="col">Başlık</Th>
                  <Th scope="col">Ekleyen</Th>
                  <Th scope="col">#</Th>
                </Tr>
              </THead>
              <TBody>
                {pages.length > 0 &&
                  pages.map((page, i) => (
                    <Tr key={i}>
                      <Th scope="row">{page.id}</Th>
                      <Td>{page.title}</Td>
                      <Td>{page.author}</Td>
                      <Td>
                        <a className="btn btn-secondary" href={`/pages/update/${page.id}`}>
                          Düzenle
                        </a>
                        <Button
                          color="warning"
                          className="ms-1"
                          onClick={() => {
                            setPage({
                              id: page.id,
                              title: page.title,
                            })
                            setRemoveVisible(true)
                          }}
                        >
                          Sil
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>
          </CCardBody>
        </CCard>
      </CCol>

      <Modal visible={removeVisible} onClose={() => setRemoveVisible(false)}>
        <ModalHeader onClose={() => setRemoveVisible(false)}>
          <ModalTitle>Sayfayı Sil</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Sayfayı silmek istediğinize emin misiniz?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => removePage()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Sil
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setRemoveVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default All
