import React, { Suspense, useState, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'
import { useNavigate } from 'react-router-dom'

// routes config
import routes from '../routes'

const ProtectedRoute = (props) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkUserToken = () => {
    const userToken = localStorage.getItem('auth-token')
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false)
      return navigate('/')
    }

    setIsLoggedIn(true)
  }

  useEffect(() => {
    checkUserToken()
    // eslint-disable-next-line
  }, [isLoggedIn])

  return <>{isLoggedIn ? props.children : null}</>
}

const PublicRoute = (props) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkUserToken = () => {
    const userToken = localStorage.getItem('auth-token')

    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false)
      return navigate('/')
    } else {
      navigate('/dashboard')
    }

    setIsLoggedIn(true)
  }

  useEffect(() => {
    checkUserToken()
    // eslint-disable-next-line
  }, [isLoggedIn])

  return <>{!isLoggedIn ? props.children : null}</>
}

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkUserToken = () => {
    const userToken = localStorage.getItem('auth-token')
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false)
    } else {
      setIsLoggedIn(true)
    }
  }

  useEffect(() => {
    checkUserToken()
    // eslint-disable-next-line
  }, [isLoggedIn])

  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            return route.element && route.public ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={
                  <PublicRoute>
                    <route.element />
                  </PublicRoute>
                }
              />
            ) : (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={
                  <ProtectedRoute>
                    <route.element />
                  </ProtectedRoute>
                }
              />
            )
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
