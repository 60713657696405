// Dashboard
import Dashboard from '../views/dashboard/Dashboard'

// Tags
import Tags from '../views/tags/all'
import AddTag from '../views/tags/add'
import RemovedTags from '../views/tags/removed'

// Categories
import Categories from '../views/categories/all'
import AddCategory from '../views/categories/add'
import RemovedCategories from '../views/categories/removed'

// Contents
import Contents from '../views/contents/all'
import IHAContents from '../views/contents/iha'
import AddContent from '../views/contents/add'
import UpdateContent from '../views/contents/update'
import RemovedContents from '../views/contents/removed'

// Adverties
import Adverties from '../views/adverties/all'
import AddAdvert from '../views/adverties/add'
import UpdateAdvert from '../views/adverties/update'
import RemovedAdverties from '../views/adverties/removed'

// Pages
import Pages from '../views/pages/all'
import AddPage from '../views/pages/add'
import UpdatePage from '../views/pages/update'
import RemovedPages from '../views/pages/removed'

// Administrators
import Admins from '../views/users/all'
import AddAdmin from '../views/users/add'
import UpdateAdmin from '../views/users/update'

// Writers
import Writers from '../views/writers/all'
import AddWriter from '../views/writers/add'
import UpdateWriter from '../views/writers/update'

// Settings
import Settings from '../views/settings'

// Gallery
import Gallery from '../views/gallery'

// Login
import Login from '../views/login'

// Protected Routes
const authProtectedRoutes = [
  // Dashboard
  { path: '/dashboard', component: Dashboard, private: true },

  // Tags
  { path: '/tags/all', component: Tags, private: true },
  { path: '/tags/add', component: AddTag, private: true },
  { path: '/tags/removed', component: RemovedTags, private: true },

  // Categories
  { path: '/categories/all', component: Categories, private: true },
  { path: '/categories/add', component: AddCategory, private: true },
  { path: '/categories/removed', component: RemovedCategories, private: true },

  // Contents
  { path: '/contents/all', component: Contents, private: true },
  { path: '/contents/iha', component: IHAContents, private: true },
  { path: '/contents/add', component: AddContent, private: true },
  { path: '/contents/update/:id', component: UpdateContent, private: true },
  { path: '/contents/removed', component: RemovedContents, private: true },

  // Adverties
  { path: '/adverties/all', component: Adverties, private: true },
  { path: '/adverties/add', component: AddAdvert, private: true },
  { path: '/adverties/update/:id', component: UpdateAdvert, private: true },
  { path: '/adverties/removed', component: RemovedAdverties, private: true },

  // Pages
  { path: '/pages/all', component: Pages, private: true },
  { path: '/pages/add', component: AddPage, private: true },
  { path: '/pages/update/:id', component: UpdatePage, private: true },
  { path: '/pages/removed', component: RemovedPages, private: true },

  // Administrators
  { path: '/admins/all', component: Admins, private: true },
  { path: '/admins/add', component: AddAdmin, private: true },
  { path: '/admins/update/:id', component: UpdateAdmin, private: true },

  // Writers
  { path: '/writers/all', component: Writers, private: true },
  { path: '/writers/add', component: AddWriter, private: true },
  { path: '/writers/update/:id', component: UpdateWriter, private: true },

  // Settings
  { path: '/settings', component: Settings, private: true },

  // Gallery
  { path: '/gallery', component: Gallery, private: true },

  // Login
  { path: '/', component: Login, private: false },
]

export { authProtectedRoutes }
