import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import api from 'src/api'

function AddUser() {
  const [loading, setLoading] = useState(false)

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      fullname: '',
      username: '',
      password: '',
      confirm_password: '',
      email: '',
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string().required('Yöneticinin adını girmelisiniz.'),
      username: Yup.string().required('Yöneticinin kullanıcı adını girmelisiniz.'),
      email: Yup.string().required('Eposta adresi girmelisiniz.'),
      password: Yup.string()
        .min(8, 'Şifreniz en az 8 karakter olmalıdır.')
        .required('Şifre girmelisiniz.'),
      confirm_password: Yup.string()
        .required('Şifreyi tekrar girmelisiniz.')
        .oneOf([Yup.ref('password'), null], 'Şifreler eşleşmelidir.'),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      const add = await api.user.add(values)

      if (add.success) {
        setLoading(false)
        toast.success(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        setTimeout(() => window.location.reload(), 2000)
      } else {
        setLoading(false)
        toast.error(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Yönetici Ekle</strong>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={handleSubmit}>
              <div className="mb-3">
                <CFormLabel htmlFor="fullname">Adı</CFormLabel>
                <CFormInput
                  type="text"
                  id="fullname"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.fullname}
                />

                {errors.fullname && touched.fullname && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.fullname}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="username">Kullanıcı adı</CFormLabel>
                <CFormInput
                  type="text"
                  id="username"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.username}
                />

                {errors.username && touched.username && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.username}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="email">Eposta adresi</CFormLabel>
                <CFormInput
                  type="text"
                  id="email"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.email}
                />

                {errors.email && touched.email && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.email}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="password">Şifre</CFormLabel>
                <CFormInput
                  type="password"
                  id="password"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.password}
                />

                {errors.password && touched.password && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.password}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="confirm_password">Şifre</CFormLabel>
                <CFormInput
                  type="password"
                  id="confirm_password"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.confirm_password}
                />

                {errors.confirm_password && touched.confirm_password && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.confirm_password}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="col-auto">
                <CButton type="submit" className="mb-3" disabled={loading}>
                  {loading && <CSpinner size="sm" className="me-2" />}
                  Kaydet
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>

      <ToastContainer />
    </CRow>
  )
}

export default AddUser
