import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './scss/style.scss'

import Routes from './routes'

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes />
      </div>
    </BrowserRouter>
  )
}

export default App
