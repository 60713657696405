import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
  CFormTextarea,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import ListView from 'react-drag-listview'

import api from 'src/api'
import CIcon from '@coreui/icons-react'
import { cilCursorMove, cilMinus, cilPlus } from '@coreui/icons'

function Settings() {
  const [loading, setLoading] = useState(false)
  const [setting, setSetting] = useState({})
  const [categories, setCategories] = useState([])
  const [menu, setMenu] = useState([])
  const [pageType, setPageType] = useState(null)

  const [menuVisible, setMenuVisible] = useState(false)

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: setting?.title || '',
      description: setting?.description || '',
      instagram: setting?.social?.find((e) => e.instagram).instagram || '',
      facebook: setting?.social?.find((e) => e.facebook).facebook || '',
      twitter: setting?.social?.find((e) => e.twitter).twitter || '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Site başlığı girmelisiniz.'),
      description: Yup.string().required('Site açıklaması girmelisiniz.'),
      instagram: Yup.string(),
      facebook: Yup.string(),
      twitter: Yup.string(),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      const menus = menu.map((cat) => ({ id: cat.id, name: cat.name }))

      if (pageType === 'add') {
        const add = await api.settings.add({
          ...values,
          social: [
            { instagram: values.instagram },
            { facebook: values.facebook },
            { twitter: values.twitter },
          ],
          menu: menus,
        })

        if (add.success) {
          setLoading(false)
          toast.success(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

          setTimeout(() => window.location.reload(), 2000)
        } else {
          setLoading(false)
          toast.error(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
        }
      }

      if (pageType === 'update') {
        const update = await api.settings.update({
          ...values,
          social: [
            { instagram: values.instagram },
            { facebook: values.facebook },
            { twitter: values.twitter },
          ],
          menu: menus,
          id: 1,
        })

        if (update.success) {
          setLoading(false)
          toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

          setTimeout(() => window.location.reload(), 2000)
        } else {
          setLoading(false)
          toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
        }
      }
    },
  })

  async function get() {
    const settings = await api.settings.get(1)

    if (settings.success) {
      setPageType('update')
      setSetting(settings?.setting[0])
      setMenu(settings?.setting[0]?.menu)
    } else {
      setPageType('add')
    }

    const categoryList = await api.category.all(true)

    if (categoryList) {
      setCategories(categoryList)
    }
  }

  useEffect(() => {
    get()
  }, [])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...menu]
      const item = data.splice(fromIndex, 1)[0]
      data.splice(toIndex, 0, item)

      setMenu(data)
    },
    nodeSelector: 'li',
    handleSelector: 'svg',
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CForm onSubmit={handleSubmit}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Ayarlar</strong>
            </CCardHeader>
            <CCardBody>
              <div className="mb-3">
                <CFormLabel htmlFor="title">Site Başlığı</CFormLabel>
                <CFormInput
                  type="text"
                  id="title"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.title}
                />

                {errors.title && touched.title && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.title}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="description">Site Açıklaması</CFormLabel>
                <CFormTextarea
                  id="description"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.description}
                />

                {errors.description && touched.description && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.description}
                    </CFormFeedback>
                  </div>
                )}
              </div>
            </CCardBody>
          </CCard>

          <CCard className="mb-4">
            <CCardHeader>
              <strong>Sosyal Medya</strong>
            </CCardHeader>
            <CCardBody>
              <div className="mb-3">
                <CFormLabel htmlFor="instagram">Instagram</CFormLabel>
                <CFormInput
                  type="text"
                  id="instagram"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.instagram}
                />
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="facebook">Facebook</CFormLabel>
                <CFormInput
                  type="text"
                  id="facebook"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.facebook}
                />
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="twitter">Twitter</CFormLabel>
                <CFormInput
                  type="text"
                  id="twitter"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.twitter}
                />
              </div>
            </CCardBody>
          </CCard>

          <CCard className="mb-4">
            <CCardHeader
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <strong>Menu</strong>
              {categories.length !== menu.length && (
                <CButton
                  color="success"
                  className="text-white ml-auto"
                  onClick={() => setMenuVisible(true)}
                >
                  Yeni Ekle
                </CButton>
              )}
            </CCardHeader>
            <CCardBody>
              <div className="mb-3">
                <ListView {...dragProps}>
                  <ul className="list-group">
                    {menu &&
                      menu?.map((item, index) => (
                        <li
                          key={index}
                          className="list-group-item"
                          style={{
                            cursor: 'move',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <CIcon icon={cilCursorMove} className="me-2" />
                          {item.name}

                          <CButton
                            color="danger"
                            className="text-white"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => setMenu(menu.filter((e) => e.id !== item.id))}
                          >
                            <CIcon icon={cilMinus} />
                          </CButton>
                        </li>
                      ))}
                  </ul>
                </ListView>
              </div>
            </CCardBody>
          </CCard>

          <div className="col-auto">
            <CButton type="submit" className="mb-3" disabled={loading}>
              {loading && <CSpinner size="sm" className="me-2" />}
              Kaydet
            </CButton>
          </div>
        </CForm>
      </CCol>

      <Modal visible={menuVisible} onClose={() => setMenuVisible(false)}>
        <ModalHeader onClose={() => setMenuVisible(false)}>
          <ModalTitle>Menü Ekle</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ul className="list-group">
            {categories
              ?.filter((e) => !menu.find((a) => a.id === e.id))
              .map((item, index) => (
                <li
                  key={index}
                  className="list-group-item list-group-item-action"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setMenu([...menu, { id: item.id, name: item.name }])}
                >
                  <CIcon icon={cilPlus} className="me-2" />
                  {item.name}
                </li>
              ))}
          </ul>
        </ModalBody>
        <ModalFooter>
          <CButton color="danger" className="ms-1" onClick={() => setMenuVisible(false)}>
            Kapat
          </CButton>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default Settings
