import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable as Table,
  CTableBody as TBody,
  CTableDataCell as Td,
  CTableHead as THead,
  CTableHeaderCell as Th,
  CTableRow as Tr,
  CButton as Button,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CSpinner,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import api from 'src/api'

function All() {
  const [contents, setContents] = useState([])
  const [content, setContent] = useState({})
  const [removeVisible, setRemoveVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  async function fetchContents() {
    const get = await api.content.all(false)

    if (get) {
      setContents(get)
    }
  }

  async function removeContent() {
    // remove
    setLoading(true)

    const update = await api.content.update({ id: content.id, status: true })

    if (update.success) {
      setLoading(false)
      setRemoveVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchContents()
    } else {
      setLoading(false)
      setRemoveVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  useEffect(() => {
    fetchContents()
  }, [])

  async function updateContent(id, published) {
    const update = await api.content.update({ id, publish: !published, status: true })

    if (update.success) {
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      fetchContents()
    } else {
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      fetchContents()
    }
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Silinmiş Haberler</strong>
          </CCardHeader>
          <CCardBody>
            <Table>
              <THead>
                <Tr>
                  <Th scope="col">ID</Th>
                  <Th scope="col" colSpan={5}>
                    Başlık
                  </Th>
                  <Th scope="col">Ekleyen</Th>
                  <Th scope="col" colSpan={3}>
                    #
                  </Th>
                </Tr>
              </THead>
              <TBody>
                {contents.length > 0 &&
                  contents.map((content, i) => (
                    <Tr key={i}>
                      <Th scope="row">{content.id}</Th>
                      <Td colSpan={5}>{content.title}</Td>
                      <Td>{content.author}</Td>
                      <Td colSpan={3} width="80px">
                        <a
                          className="btn btn-secondary text-white"
                          href={`/contents/update/${content.id}`}
                        >
                          Düzenle
                        </a>
                        <Button
                          color="warning"
                          className="ms-1 text-white"
                          onClick={() => {
                            setContent({
                              id: content.id,
                              title: content.title,
                            })
                            setRemoveVisible(true)
                          }}
                        >
                          Geri Al
                        </Button>
                        <Button
                          color="success"
                          className="ms-1 text-white"
                          onClick={() => {
                            updateContent(content.id, content.publish)
                          }}
                        >
                          {content.publish ? 'Yayından Kaldır' : 'Yayına Al'}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>
          </CCardBody>
        </CCard>
      </CCol>

      <Modal visible={removeVisible} onClose={() => setRemoveVisible(false)}>
        <ModalHeader onClose={() => setRemoveVisible(false)}>
          <ModalTitle>Haberi Geri Al</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Haberi geri almak istediğinize emin misiniz?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => removeContent()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Geri Al
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setRemoveVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default All
