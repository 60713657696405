import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
  CFormTextarea,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import Select from 'react-select'

import api from 'src/api'
import { cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useParams } from 'react-router-dom'
import { NewsImage } from 'src/utils'

function UpdateContent() {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [photos, setPhotos] = useState([])
  const [content, setContent] = useState({})
  const [writers, setWriters] = useState([])

  const [visibleGallery, setVisibleGallery] = useState(false)

  const { id } = useParams('id')

  useEffect(() => {
    if (id) {
      getContent(id)
    }
  }, [id])

  async function getContent(id) {
    const content = await api.content.get(id)

    if (content.success) {
      setContent(content?.content[0])
    }
  }

  useEffect(() => {
    fetchCategories()
    fetchTags()
    fetchPhotos()
    fetchWriters()
  }, [])

  async function fetchCategories() {
    const categories = await api.category.all(true)

    if (categories) setCategories(categories)
  }

  async function fetchTags() {
    const tags = await api.tag.all(true)

    if (tags) setTags(tags)
  }

  async function fetchPhotos() {
    const gallery = await api.photo.all(true)

    if (gallery) setPhotos(gallery)
  }

  async function fetchWriters() {
    const writers = await api.writer.all()

    if (writers) setWriters(writers.filter((x) => x.status === true))
  }

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: content?.title || '',
      seoTitle: content?.seoTitle || '',
      spot: content?.spot || '',
      content: content?.content || '',
      cover: content?.cover || '',
      videoUrl: content?.videoUrl || '',
      category: content?.category || {},
      tags: content?.tags || [],
      isHomepage: content?.isHomepage || false,
      isVideo: content?.isVideo || false,
      hiddenAuthor: content?.hiddenAuthor || false,
      publish: content?.publish || false,
      isWriter: content?.isWriter || false,
      writer: content?.writer || {},
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Başlık girmelisiniz.'),
      seoTitle: Yup.string().required('SEO başlık girmelisiniz.'),
      spot: Yup.string().required('Spot girmelisiniz.'),
      content: Yup.string().required('İçerik girmelisiniz.'),
      videoUrl: Yup.string(),
      isHomepage: Yup.bool(),
      isVideo: Yup.bool(),
      hiddenAuthor: Yup.bool(),
      publish: Yup.bool(),
      category: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required('Kategori seçmelisiniz.'),
      tags: Yup.array().min(1, 'Etiket seçmelisiniz.'),
      isWriter: Yup.bool(),
    }),
    onSubmit: async (values) => {
      if (Object.keys(values.category).length <= 0) {
        alert('Kategori seçmelisiniz.')
        return false
      }

      if (values.isWriter && Object.keys(values.writer).length <= 0) {
        alert('Yazar seçmelisiniz.')
        return false
      }

      setLoading(true)

      const update = await api.content.update({ id, ...values })

      if (update.success) {
        setLoading(false)
        toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        setTimeout(() => window.location.reload(), 2000)
      } else {
        setLoading(false)
        toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  return (
    <>
      <CForm onSubmit={handleSubmit}>
        <CRow>
          <CCol xl={9} lg={7} className="mb-3">
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Haber Ekle</strong>
              </CCardHeader>
              <CCardBody>
                <div className="mb-3">
                  <CFormLabel htmlFor="title">Başlık</CFormLabel>
                  <CFormInput
                    type="text"
                    id="title"
                    placeholder="Girin"
                    onChange={handleChange}
                    value={values.title}
                  />

                  {errors.title && touched.title && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.title}
                      </CFormFeedback>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <CFormLabel htmlFor="seoTitle">SEO Başlık</CFormLabel>
                  <CFormInput
                    type="text"
                    id="seoTitle"
                    placeholder="Girin"
                    onChange={handleChange}
                    value={values.seoTitle}
                  />

                  {errors.seoTitle && touched.seoTitle && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.seoTitle}
                      </CFormFeedback>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <CFormLabel htmlFor="spot">Spot</CFormLabel>
                  <CFormTextarea
                    type="text"
                    id="spot"
                    placeholder="Girin"
                    onChange={handleChange}
                    value={values.spot}
                  />

                  {errors.spot && touched.spot && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.spot}
                      </CFormFeedback>
                    </div>
                  )}
                </div>

                {values.isVideo && (
                  <div className="mb-3">
                    <CFormLabel htmlFor="videoUrl">Video URL</CFormLabel>
                    <CFormInput
                      type="text"
                      id="videoUrl"
                      placeholder="Girin"
                      onChange={handleChange}
                      value={values.videoUrl}
                    />

                    {errors.videoUrl && touched.videoUrl && (
                      <div>
                        <CFormFeedback invalid className="d-block">
                          {errors.videoUrl}
                        </CFormFeedback>
                      </div>
                    )}
                  </div>
                )}
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <strong>Haber İçeriği</strong>
              </CCardHeader>
              <CCardBody>
                <div className="mb-3">
                  <CKEditor
                    editor={ClassicEditor}
                    data={values.content}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setFieldValue('content', data)
                    }}
                  />

                  {errors.content && touched.content && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.content}
                      </CFormFeedback>
                    </div>
                  )}
                </div>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol xl={3} lg={5}>
            <CCard className="mb-3">
              <CCardBody>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={values.publish}
                    id="publish"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="publish">
                    Yayında
                  </label>
                </div>

                <div className="d-flex flex-row align-items-center">
                  <CButton type="submit" disabled={loading}>
                    {loading && <CSpinner size="sm" className="me-2" />}
                    Kaydet
                  </CButton>
                </div>
              </CCardBody>
            </CCard>
            <CCard className="mb-3">
              <CCardBody>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={values.isHomepage}
                    id="isHomepage"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isHomepage">
                    Ana sayfada gözüksün
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={values.isVideo}
                    id="isVideo"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isVideo">
                    Video haber
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={values.hiddenAuthor}
                    id="hiddenAuthor"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="hiddenAuthor">
                    Yazarı gizle
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={values.isWriter}
                    id="isWriter"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isWriter">
                    Yazar haberi
                  </label>
                </div>
              </CCardBody>
            </CCard>

            <CCard className="mb-3">
              <CCardBody>
                {values.isWriter && (
                  <div className="mb-3">
                    <CFormLabel htmlFor="videoUrl">Yazarlar</CFormLabel>
                    <Select
                      options={writers?.map((writer) => ({
                        label: writer.fullname,
                        value: writer.id,
                      }))}
                      placeholder="Kategori seçin"
                      onChange={(e) => setFieldValue('writer', e)}
                      value={values.writer}
                    />
                  </div>
                )}
                <div className="mb-3">
                  <CFormLabel htmlFor="videoUrl">Kategoriler</CFormLabel>
                  <Select
                    options={categories?.map((category) => ({
                      label: category.name,
                      value: category.id,
                    }))}
                    placeholder="Kategori seçin"
                    onChange={(e) => setFieldValue('category', e)}
                    value={values.category}
                  />
                </div>

                <div className="mb-3">
                  <CFormLabel htmlFor="videoUrl">Etiketler</CFormLabel>
                  <Select
                    options={tags?.map((tag) => ({
                      label: tag.name,
                      value: tag.id,
                    }))}
                    isMulti
                    placeholder="Etiket seçin"
                    onChange={(e) => setFieldValue('tags', e)}
                    value={values.tags}
                  />
                </div>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardBody>
                {values.cover && (
                  <div className="col-12 mb-3 gallery-photo">
                    <img
                      src={NewsImage(values.cover)}
                      className="rounded img-fluid img-thumbnail"
                      alt=""
                    />

                    <div className="photo-edit">
                      <CButton
                        onClick={() => {
                          setFieldValue('cover', '')
                        }}
                      >
                        <CIcon icon={cilTrash} />
                      </CButton>
                    </div>
                  </div>
                )}

                <CButton
                  color="warning"
                  className="w-100 text-white"
                  onClick={() => setVisibleGallery(true)}
                >
                  Fotoğraf Seçin
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CForm>

      <ToastContainer />

      <Modal visible={visibleGallery} onClose={() => setVisibleGallery(false)} size="xl">
        <ModalHeader onClose={() => setVisibleGallery(false)}>
          <ModalTitle>Fotoğraf Galerisi</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {photos?.map((photo, i) => (
              <div className="col-2 mb-3 gallery-photo" key={i}>
                <img
                  src={NewsImage(photo.photoId)}
                  className="rounded img-fluid img-thumbnail"
                  alt=""
                />

                <div className="photo-edit">
                  <CButton
                    onClick={() => {
                      setVisibleGallery(false)
                      setFieldValue('cover', photo.photoId)
                    }}
                  >
                    <CIcon icon={cilPlus} />
                  </CButton>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default UpdateContent
