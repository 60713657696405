import React, { useCallback, useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
  CFormTextarea,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import Select from 'react-select'

import api from 'src/api'
import { cilPlus, cilSearch, cilTrash, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { NewsImage } from 'src/utils'

function AddContent() {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [photos, setPhotos] = useState([])
  const [writers, setWriters] = useState([])

  const [search, setSearch] = useState('')
  const [isSearch, setIsSearch] = useState(false)

  const [title, setTitle] = useState('')
  const [file, setFile] = useState(null)

  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleGallery, setVisibleGallery] = useState(false)

  // eslint-disable-next-line
  const debounceOnchange = useCallback(debounce(onChange, 400), [])

  useEffect(() => {
    fetchCategories()
    fetchTags()
    fetchPhotos()
    fetchWriters()
  }, [])

  async function fetchCategories() {
    const categories = await api.category.all(true)

    if (categories) setCategories(categories)
  }

  async function fetchTags() {
    const tags = await api.tag.all(true)

    if (tags) setTags(tags)
  }

  async function fetchPhotos() {
    const gallery = await api.photo.all(true)

    if (gallery) setPhotos(gallery)
  }

  async function fetchWriters() {
    const writers = await api.writer.all()

    if (writers) setWriters(writers.filter((x) => x.status === true))
  }

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    initialValues: {
      title: '',
      seoTitle: '',
      spot: '',
      content: '',
      cover: '',
      videoUrl: '',
      category: {},
      tags: [],
      isHomepage: false,
      isVideo: false,
      hiddenAuthor: false,
      publish: false,
      isWriter: false,
      writer: {},
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Başlık girmelisiniz.'),
      seoTitle: Yup.string().required('SEO başlık girmelisiniz.'),
      spot: Yup.string().required('Spot girmelisiniz.'),
      content: Yup.string().required('İçerik girmelisiniz.'),
      videoUrl: Yup.string(),
      isHomepage: Yup.bool(),
      isVideo: Yup.bool(),
      hiddenAuthor: Yup.bool(),
      publish: Yup.bool(),
      category: Yup.object().required('Kategori seçmelisiniz.'),
      tags: Yup.array().min(1, 'Etiket seçmelisiniz.'),
      isWriter: Yup.bool(),
    }),
    onSubmit: async (values) => {
      if (Object.keys(values.category).length <= 0) {
        alert('Kategori seçmelisiniz.')
        return false
      }

      if (values.isWriter && Object.keys(values.writer).length <= 0) {
        alert('Yazar seçmelisiniz.')
        return false
      }

      setLoading(true)

      const add = await api.content.add(values)

      if (add.success) {
        setLoading(false)
        toast.success(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        setTimeout(() => (window.location.href = `/contents/update/${add.id}`), 2000)
      } else {
        setLoading(false)
        toast.error(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  function debounce(func, wait) {
    let timeout
    return function (...args) {
      const context = this
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        func.apply(context, args)
      }, wait)
    }
  }

  async function onChange(value) {
    const search = await api.photo.search(value)

    if (search) {
      setPhotos(search)
      setIsSearch(true)
    }
  }

  function onCloseHandle() {
    setIsSearch(false)
    setSearch('')
    fetchPhotos()
  }

  async function handleSubmitPhoto() {
    setLoading(true)

    const data = new FormData()
    data.append('title', title)
    data.append('file', file)

    const add = await api.photo.add(data)

    if (add.success) {
      setLoading(false)
      setVisibleModal(false)
      fetchPhotos()
      setFieldValue('cover', add.photoId)
      toast.success(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    } else {
      setLoading(false)
      setVisibleModal(false)
      fetchPhotos()
      toast.error(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  return (
    <>
      <CForm onSubmit={handleSubmit}>
        <CRow>
          <CCol xl={9} lg={7} className="mb-3">
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Haber Ekle</strong>
              </CCardHeader>
              <CCardBody>
                <div className="mb-3">
                  <CFormLabel htmlFor="title">Başlık</CFormLabel>
                  <CFormInput
                    type="text"
                    id="title"
                    placeholder="Girin"
                    onChange={handleChange}
                    value={values.title}
                  />

                  {errors.title && touched.title && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.title}
                      </CFormFeedback>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <CFormLabel htmlFor="seoTitle">SEO Başlık</CFormLabel>
                  <CFormInput
                    type="text"
                    id="seoTitle"
                    placeholder="Girin"
                    onChange={handleChange}
                    value={values.seoTitle}
                  />

                  {errors.seoTitle && touched.seoTitle && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.seoTitle}
                      </CFormFeedback>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <CFormLabel htmlFor="spot">Spot</CFormLabel>
                  <CFormTextarea
                    type="text"
                    id="spot"
                    placeholder="Girin"
                    onChange={handleChange}
                    value={values.spot}
                  />

                  {errors.spot && touched.spot && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.spot}
                      </CFormFeedback>
                    </div>
                  )}
                </div>

                {values.isVideo && (
                  <div className="mb-3">
                    <CFormLabel htmlFor="videoUrl">Video URL</CFormLabel>
                    <CFormInput
                      type="text"
                      id="videoUrl"
                      placeholder="Girin"
                      onChange={handleChange}
                      value={values.videoUrl}
                    />

                    {errors.videoUrl && touched.videoUrl && (
                      <div>
                        <CFormFeedback invalid className="d-block">
                          {errors.videoUrl}
                        </CFormFeedback>
                      </div>
                    )}
                  </div>
                )}
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <strong>Haber İçeriği</strong>
              </CCardHeader>
              <CCardBody>
                <div className="mb-3">
                  <CKEditor
                    editor={ClassicEditor}
                    data={values.content}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setFieldValue('content', data)
                    }}
                  />

                  {errors.content && touched.content && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.content}
                      </CFormFeedback>
                    </div>
                  )}
                </div>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol xl={3} lg={5}>
            <CCard className="mb-3">
              <CCardBody>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={values.publish}
                    id="publish"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="publish">
                    Yayında
                  </label>
                </div>

                <div className="d-flex flex-row align-items-center">
                  <CButton type="submit" disabled={loading}>
                    {loading && <CSpinner size="sm" className="me-2" />}
                    Kaydet
                  </CButton>
                </div>
              </CCardBody>
            </CCard>
            <CCard className="mb-3">
              <CCardBody>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={values.isHomepage}
                    id="isHomepage"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isHomepage">
                    Ana sayfada gözüksün
                  </label>
                </div>

                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={values.isVideo}
                    id="isVideo"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isVideo">
                    Video haber
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={values.hiddenAuthor}
                    id="hiddenAuthor"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="hiddenAuthor">
                    Yazarı gizle
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={values.isWriter}
                    id="isWriter"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isWriter">
                    Yazar haberi
                  </label>
                </div>
              </CCardBody>
            </CCard>

            <CCard className="mb-3">
              <CCardBody>
                {values.isWriter && (
                  <div className="mb-3">
                    <CFormLabel htmlFor="writers">Yazarlar</CFormLabel>
                    <Select
                      options={writers?.map((writer) => ({
                        label: writer.fullname,
                        value: writer.id,
                      }))}
                      placeholder="Kategori seçin"
                      onChange={(e) => setFieldValue('writer', e)}
                      id="writer"
                      value={values.writer}
                    />

                    {errors.writer && touched.writer && (
                      <div>
                        <CFormFeedback invalid className="d-block">
                          {errors.writer}
                        </CFormFeedback>
                      </div>
                    )}
                  </div>
                )}

                <div className="mb-3">
                  <CFormLabel htmlFor="videoUrl">Kategoriler</CFormLabel>
                  <Select
                    options={categories?.map((category) => ({
                      label: category.name,
                      value: category.id,
                    }))}
                    placeholder="Kategori seçin"
                    onChange={(e) => setFieldValue('category', e)}
                    id="category"
                    value={values.category}
                  />

                  {errors.category && touched.category && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.category}
                      </CFormFeedback>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <CFormLabel htmlFor="videoUrl">Etiketler</CFormLabel>
                  <Select
                    options={tags?.map((tag) => ({
                      label: tag.name,
                      value: tag.id,
                    }))}
                    isMulti
                    placeholder="Etiket seçin"
                    onChange={(e) => setFieldValue('tags', e)}
                    value={values.tags}
                    id="tags"
                  />

                  {errors.tags && touched.tags && (
                    <div>
                      <CFormFeedback invalid className="d-block">
                        {errors.tags}
                      </CFormFeedback>
                    </div>
                  )}
                </div>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardBody>
                {values.cover && (
                  <div className="col-12 mb-3 gallery-photo">
                    <img
                      src={NewsImage(values.cover)}
                      className="rounded img-fluid img-thumbnail"
                      alt=""
                    />

                    <div className="photo-edit">
                      <CButton
                        onClick={() => {
                          setFieldValue('cover', '')
                        }}
                      >
                        <CIcon icon={cilTrash} />
                      </CButton>
                    </div>
                  </div>
                )}

                <CButton
                  color="warning"
                  className="w-100 text-white"
                  onClick={() => setVisibleGallery(true)}
                >
                  Fotoğraf Seçin
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CForm>

      <ToastContainer />

      <Modal
        visible={visibleGallery}
        onClose={() => setVisibleGallery(false)}
        size="xl"
        scrollable
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader onClose={() => setVisibleGallery(false)}>
          <ModalTitle className="w-100">
            <div className="d-flex flex-row align-items-center justify-content-between me-4">
              <strong className="text-nowrap">Fotoğraf Galerisi</strong>

              <div className="d-flex flex-row">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Fotoğraf ara"
                    className="form-control"
                    onChange={(e) => {
                      debounceOnchange(e.target.value)
                      setSearch(e.target.value)
                    }}
                    value={search}
                  />
                  <span className="input-group-text" id="basic-addon1">
                    <CIcon icon={cilSearch} />
                  </span>
                </div>

                {isSearch && (
                  <div
                    onClick={() => onCloseHandle()}
                    className="btn btn-info text-nowrap me-4 ms-2 text-white"
                  >
                    <CIcon icon={cilX} />
                  </div>
                )}
              </div>

              <div
                onClick={() => {
                  setVisibleModal(true)
                  setVisibleGallery(false)
                }}
                className="btn btn-info text-nowrap ms-2 text-white"
              >
                Ekle
              </div>
            </div>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {photos?.map((photo, i) => (
              <div className="col-2 mb-3 gallery-photo" key={i}>
                <img
                  src={NewsImage(photo.photoId)}
                  className="rounded img-fluid img-thumbnail"
                  alt=""
                />

                <div className="photo-edit">
                  <CButton
                    onClick={() => {
                      setVisibleGallery(false)
                      setFieldValue('cover', photo.photoId)
                    }}
                  >
                    <CIcon icon={cilPlus} />
                  </CButton>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <ModalHeader onClose={() => setVisibleModal(false)}>
          <ModalTitle>Görsel Ekle</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form>
            <input
              type="text"
              placeholder="Başlık girin"
              required
              className="form-control mb-3"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="file"
              className="form-control"
              required
              onChange={(e) => setFile(e.target.files[0])}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <CButton color="primary" onClick={handleSubmitPhoto}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Ekle
          </CButton>
          <CButton color="danger" className="ms-1" onClick={() => setVisibleModal(false)}>
            Vazgeç
          </CButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddContent
