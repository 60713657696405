import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable as Table,
  CTableBody as TBody,
  CTableDataCell as Td,
  CTableHead as THead,
  CTableHeaderCell as Th,
  CTableRow as Tr,
  CButton as Button,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CSpinner,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import api from 'src/api'

function All() {
  const [adverties, setAdverties] = useState([])
  const [advert, setAdvert] = useState({})
  const [removeVisible, setRemoveVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  async function fetchAdverties() {
    const get = await api.advert.all(true)

    if (get) {
      setAdverties(get)
    }
  }

  async function removeAdvert() {
    // remove
    setLoading(true)

    const update = await api.advert.update({ id: advert.id, status: false })

    if (update.success) {
      setLoading(false)
      setRemoveVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchAdverties()
    } else {
      setLoading(false)
      setRemoveVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  useEffect(() => {
    fetchAdverties()
  }, [])

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Reklamlar</strong>
          </CCardHeader>
          <CCardBody>
            <Table>
              <THead>
                <Tr>
                  <Th scope="col">ID</Th>
                  <Th scope="col">Bölüm</Th>
                  <Th scope="col">Ekleyen</Th>
                  <Th scope="col">#</Th>
                </Tr>
              </THead>
              <TBody>
                {adverties.length > 0 &&
                  adverties.map((advert, i) => (
                    <Tr key={i}>
                      <Th scope="row">{advert.id}</Th>
                      <Td>{advert.section}</Td>
                      <Td>{advert.author}</Td>
                      <Td>
                        <a className="btn btn-secondary" href={`/adverties/update/${advert.id}`}>
                          Düzenle
                        </a>
                        <Button
                          color="warning"
                          className="ms-1"
                          onClick={() => {
                            setAdvert({
                              id: advert.id,
                              section: advert.title,
                              photo: advert.photo,
                              url: advert.url,
                            })
                            setRemoveVisible(true)
                          }}
                        >
                          Sil
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>
          </CCardBody>
        </CCard>
      </CCol>

      <Modal visible={removeVisible} onClose={() => setRemoveVisible(false)}>
        <ModalHeader onClose={() => setRemoveVisible(false)}>
          <ModalTitle>Reklamı Sil</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Reklamı silmek istediğinize emin misiniz?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => removeAdvert()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Sil
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setRemoveVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default All
