import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import api from 'src/api'

function Login() {
  const [loading, setLoading] = useState(false)

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Kullanıcı adı girmelisiniz.'),
      password: Yup.string().required('Şifre girmelisiniz.'),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      const login = await api.user.login(values)

      if (login.success) {
        setLoading(false)
        toast.success(login.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        localStorage.setItem('auth-token', login.token)
        setTimeout(() => window.location.reload(), 1500)
      } else {
        setLoading(false)
        toast.error(login.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Giriş Yap</h1>

                    <div className="mb-3 mt-5">
                      <CInputGroup>
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>

                        <CFormInput
                          id="username"
                          placeholder="Kullanıcı adı"
                          onChange={handleChange}
                          value={values.username}
                        />
                      </CInputGroup>

                      {errors.username && touched.username && (
                        <div>
                          <CFormFeedback invalid className="d-block">
                            {errors.username}
                          </CFormFeedback>
                        </div>
                      )}
                    </div>

                    <div className="mb-4">
                      <CInputGroup>
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>

                        <CFormInput
                          type="password"
                          id="password"
                          placeholder="Şifre"
                          onChange={handleChange}
                          value={values.password}
                        />
                      </CInputGroup>

                      {errors.password && touched.password && (
                        <div>
                          <CFormFeedback invalid className="d-block">
                            {errors.password}
                          </CFormFeedback>
                        </div>
                      )}
                    </div>

                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4" disabled={loading}>
                          {loading && <CSpinner size="sm" className="me-2" />}
                          Giriş Yap
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

      <ToastContainer />
    </div>
  )
}

export default Login
