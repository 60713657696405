import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton as Button,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CSpinner,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import 'moment/locale/tr'
import api from 'src/api'
import CIcon from '@coreui/icons-react'
import { cilImage, cilNewspaper } from '@coreui/icons'

moment.locale('tr')

// ETİKET ID 58, ETİKET ADI Neox Medya, Obje
// KATEGORİ ID 7, KATEGORİ ADI Neox Plus, Dizi

function All() {
  const [contents, setContents] = useState([])
  const [content, setContent] = useState({})
  const [addContentModal, setAddContentModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [showMedia, setShowMedia] = useState(false)
  const [gallery, setGallery] = useState([])

  async function fetchContents() {
    const get = await api.content.iha()

    if (get.length > 0) {
      setContents(get)
    }
  }

  useEffect(() => {
    timer()
    // eslint-disable-next-line
  }, [])

  function timer() {
    fetchContents()

    setTimeout(() => {
      timer()
    }, 1000 * 60 * 1)
  }

  async function addContent() {
    // remove
    setLoading(true)

    const add = await api.content.add({
      ...content,
      category: { label: 'Neox Plus', value: 7 },
      tags: [{ label: 'Neox Medya', value: 58 }],
    })

    // const update = await api.content.update({ id: content.id, status: false, publish: false })

    if (add.success) {
      setLoading(false)
      toast.success(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      setTimeout(() => (window.location.href = `/contents/update/${add.id}`), 2000)
    } else {
      setLoading(false)
      toast.error(add.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  return (
    <CRow>
      <CCol xs={12}>
        <div className="alert alert-warning" role="alert">
          Sayfanın sürekli yenilenmesi kısa süreli İHA kaynaklarından bloklanmaya neden olabilir. Bu
          durumda en az 30 saniye beklemelisiniz.
        </div>

        <div className="alert alert-info" role="alert">
          Sayfayı açık bıraktığınız sürece her dakika otomatik güncellenir.
        </div>

        <div className="alert alert-info" role="alert">
          Haberleştirmeden önce haber fotoğraflarını (varsa) indirdiğinizden emin olun.
        </div>

        <CCard className="mb-4">
          <CCardHeader>
            <strong>Haberler</strong>
          </CCardHeader>
          <CCardBody>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Başlık</th>
                  <th>Kategori</th>
                  <th>Eklenme/Düzenlenme T.</th>
                  <th>Fotoğraf</th>
                  <th>#</th>
                </tr>
              </thead>

              <tbody>
                {contents.length > 0 &&
                  contents.map((content, i) => (
                    <tr key={i}>
                      <td>{content?.HaberKodu}</td>
                      <td>{content?.title}</td>
                      <td>
                        {content?.UstKategori}, {content?.Kategori}
                      </td>
                      <td>
                        <span>
                          {moment(content?.pubDate, 'DD.MM.YYYY HH:mm:ss').format(
                            'DD MMM YYYY, HH:mm',
                          )}
                        </span>
                      </td>
                      <td>
                        {typeof content?.images !== 'undefined' ? (
                          <Button
                            color="info"
                            className="ms-1 text-white btn-sm"
                            onClick={() => {
                              setShowMedia(true)
                              setGallery(content?.images)
                            }}
                          >
                            <CIcon icon={cilImage} />
                          </Button>
                        ) : (
                          'Yok'
                        )}
                      </td>
                      <td>
                        <Button
                          color="success"
                          className="ms-1 text-white btn-sm"
                          onClick={() => {
                            setContent({
                              title: content?.title[0],
                              seoTitle: content?.title[0],
                              spot: content?.description[0].split('.')[0] + '.',
                              content: content?.description[0],
                            })
                            setAddContentModal(true)
                          }}
                        >
                          <CIcon icon={cilNewspaper} />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>

      <Modal
        visible={showMedia}
        onClose={() => {
          setShowMedia(false)
          setGallery([])
        }}
        size="lg"
      >
        <ModalHeader
          onClose={() => {
            setShowMedia(false)
            setGallery([])
          }}
        >
          <ModalTitle>Fotoğraf Galerisi</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {gallery.length > 0 &&
              gallery[0].image.map((item, i) => (
                <div className="col-lg-3" key={i}>
                  <img
                    src={`https://cdn.iha.com.tr/Contents/images/${item.$.HaberKodu.slice(
                      0,
                      4,
                    )}/${item.$.HaberKodu.slice(4, 6)}/${item.$.HaberKodu.slice(
                      6,
                      8,
                    )}/${item.$.HaberKodu.toLowerCase()}-${i}.jpg`}
                    style={{ width: '100%', maxHeight: 200 }}
                    className="rounded"
                    alt=""
                  />

                  <a href={item._} className="btn btn-primary mt-2">
                    İndir
                  </a>
                </div>
              ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        visible={addContentModal}
        onClose={() => {
          setAddContentModal(false)
          setContent({})
        }}
      >
        <ModalHeader
          onClose={() => {
            setAddContentModal(false)
            setContent({})
          }}
        >
          <ModalTitle>Haberleştir</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Haberleştirmek istediğinize emin misiniz?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => addContent()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Evet
          </Button>
          <Button
            color="danger"
            className="ms-1"
            onClick={() => {
              setAddContentModal(false)
              setContent({})
            }}
          >
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default All
