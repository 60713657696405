import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilTags,
  cilNewspaper,
  cilList,
  cilStar,
  cilImage,
  cilCog,
  cilTv,
  cilUser,
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavGroup,
    name: 'Etiket Yönetimi',
    to: '/tags',
    icon: <CIcon icon={cilTags} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Etiketler',
        to: '/tags/all',
      },
      {
        component: CNavItem,
        name: 'Ekle',
        to: '/tags/add',
      },
      {
        component: CNavItem,
        name: 'Silinmiş Etiketler',
        to: '/tags/removed',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Kategori Yönetimi',
    to: '/categories',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Kategoriler',
        to: '/categories/all',
      },
      {
        component: CNavItem,
        name: 'Ekle',
        to: '/categories/add',
      },
      {
        component: CNavItem,
        name: 'Silinmiş Kategoriler',
        to: '/categories/removed',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Haber Yönetimi',
    icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
    to: '/contents',
    items: [
      {
        component: CNavItem,
        name: 'Haberler',
        to: '/contents/all',
      },
      {
        component: CNavItem,
        name: 'İHA Haberleri',
        to: '/contents/iha',
      },
      {
        component: CNavItem,
        name: 'Haber Ekle',
        to: '/contents/add',
      },
      {
        component: CNavItem,
        name: 'Silinmiş Haberler',
        to: '/contents/removed',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Sayfa Yönetimi',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    to: '/pages',
    items: [
      {
        component: CNavItem,
        name: 'Sayfalar',
        to: '/pages/all',
      },
      {
        component: CNavItem,
        name: 'Sayfa Ekle',
        to: '/pages/add',
      },
      {
        component: CNavItem,
        name: 'Silinmiş Sayfalar',
        to: '/pages/removed',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Fotoğraf Galerisi',
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
    to: '/gallery',
  },
  {
    component: CNavGroup,
    name: 'Yazar Yönetimi',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    to: '/writers',
    items: [
      {
        component: CNavItem,
        name: 'Yazarlar',
        to: '/writers/all',
      },
      {
        component: CNavItem,
        name: 'Ekle',
        to: '/writers/add',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Reklam Yönetimi',
    icon: <CIcon icon={cilTv} customClassName="nav-icon" />,
    to: '/adverties',
    items: [
      {
        component: CNavItem,
        name: 'Reklamlar',
        to: '/adverties/all',
      },
      {
        component: CNavItem,
        name: 'Ekle',
        to: '/adverties/add',
      },
      {
        component: CNavItem,
        name: 'Silinmiş Reklamlar',
        to: '/adverties/removed',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Yönetici Yönetimi',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    to: '/admins',
    items: [
      {
        component: CNavItem,
        name: 'Yöneticiler',
        to: '/admins/all',
      },
      {
        component: CNavItem,
        name: 'Ekle',
        to: '/admins/add',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Ayarlar',
    icon: <CIcon icon={cilCog} customClassName="nav-icon" />,
    to: '/settings',
  },
]

export default _nav
