import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable as Table,
  CTableBody as TBody,
  CTableDataCell as Td,
  CTableHead as THead,
  CTableHeaderCell as Th,
  CTableRow as Tr,
  CButton as Button,
  CModal as Modal,
  CModalHeader as ModalHeader,
  CModalTitle as ModalTitle,
  CModalBody as ModalBody,
  CModalFooter as ModalFooter,
  CFormInput as Input,
  CSpinner,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import api from 'src/api'

function All() {
  const [tags, setTags] = useState([])
  const [tag, setTag] = useState({})
  const [updateVisible, setUpdateVisible] = useState(false)
  const [removeVisible, setRemoveVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  async function fetchTags() {
    const get = await api.tag.all(true)

    if (get) {
      setTags(get)
    }
  }

  async function updateTag() {
    // update
    setLoading(true)

    const update = await api.tag.update(tag)

    if (update.success) {
      setLoading(false)
      setUpdateVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchTags()
    } else {
      setLoading(false)
      setUpdateVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  async function removeTag() {
    // remove
    setLoading(true)

    const update = await api.tag.update({ id: tag.id, status: false })

    if (update.success) {
      setLoading(false)
      setRemoveVisible(false)
      toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

      fetchTags()
    } else {
      setLoading(false)
      setRemoveVisible(false)
      toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
    }
  }

  useEffect(() => {
    fetchTags()
  }, [])

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Etiketler</strong>
          </CCardHeader>
          <CCardBody>
            <Table>
              <THead>
                <Tr>
                  <Th scope="col">ID</Th>
                  <Th scope="col">Adı</Th>
                  <Th scope="col">Ekleyen</Th>
                  <Th scope="col">#</Th>
                </Tr>
              </THead>
              <TBody>
                {tags.length > 0 &&
                  tags.map((tag, i) => (
                    <Tr key={i}>
                      <Th scope="row">{tag.id}</Th>
                      <Td>{tag.name}</Td>
                      <Td>{tag.author}</Td>
                      <Td>
                        <Button
                          color="secondary"
                          onClick={() => {
                            setTag({ id: tag.id, name: tag.name })
                            setUpdateVisible(true)
                          }}
                        >
                          Düzenle
                        </Button>
                        <Button
                          color="warning"
                          className="ms-1"
                          onClick={() => {
                            setTag({ id: tag.id, name: tag.name })
                            setRemoveVisible(true)
                          }}
                        >
                          Sil
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>
          </CCardBody>
        </CCard>
      </CCol>

      <Modal visible={updateVisible} onClose={() => setUpdateVisible(false)}>
        <ModalHeader onClose={() => setUpdateVisible(false)}>
          <ModalTitle>Etiketi Düzenle</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form>
            <Input
              type="text"
              placeholder="Girin"
              required
              value={tag?.name}
              onChange={(e) => setTag({ ...tag, name: e.target.value })}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => updateTag()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Güncelle
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setUpdateVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <Modal visible={removeVisible} onClose={() => setRemoveVisible(false)}>
        <ModalHeader onClose={() => setRemoveVisible(false)}>
          <ModalTitle>Etiketi Sil</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Etiketi silmek istediğinize emin misiniz?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => removeTag()}>
            {loading && <CSpinner size="sm" className="me-2" />}
            Sil
          </Button>
          <Button color="danger" className="ms-1" onClick={() => setRemoveVisible(false)}>
            Vazgeç
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </CRow>
  )
}

export default All
