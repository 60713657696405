import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CFormFeedback,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'

import api from 'src/api'

function UpdateAdvert() {
  const [loading, setLoading] = useState(false)
  const [advert, setAdvert] = useState({})

  const { id } = useParams('id')

  useEffect(() => {
    if (id) {
      getAdvert(id)
    }
  }, [id])

  async function getAdvert(id) {
    const advert = await api.advert.get(id)

    if (advert.success) {
      setAdvert(advert?.advert[0])
    }
  }

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      section: advert?.section || '',
      photo: advert?.photo || '',
      url: advert?.url || '',
    },
    validationSchema: Yup.object().shape({
      section: Yup.string().required('Reklam bölümü belirtmelisiniz.'),
      photo: Yup.string().required('Fotoğraf seçmelisiniz.'),
      url: Yup.string().required('Reklam adresi girmelisiniz.'),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      const update = await api.advert.update({ ...values, id })

      if (update.success) {
        setLoading(false)
        toast.success(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })

        setTimeout(() => window.location.reload(), 2000)
      } else {
        setLoading(false)
        toast.error(update.message, { position: toast.POSITION.TOP_RIGHT, progress: false })
      }
    },
  })

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Reklamlar</strong>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={handleSubmit}>
              <div className="mb-3">
                <CFormLabel htmlFor="section">Reklam bölümü</CFormLabel>
                <CFormInput
                  type="text"
                  id="section"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.section}
                />

                {errors.section && touched.section && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.section}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="url">Reklam Adresi</CFormLabel>
                <CFormInput
                  type="text"
                  id="url"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.url}
                />

                {errors.url && touched.url && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.url}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="photo">Reklam Görseli</CFormLabel>
                <CFormInput
                  type="text"
                  id="photo"
                  placeholder="Girin"
                  onChange={handleChange}
                  value={values.photo}
                />

                {errors.photo && touched.photo && (
                  <div>
                    <CFormFeedback invalid className="d-block">
                      {errors.photo}
                    </CFormFeedback>
                  </div>
                )}
              </div>

              <div className="col-auto">
                <CButton type="submit" className="mb-3" disabled={loading}>
                  {loading && <CSpinner size="sm" className="me-2" />}
                  Kaydet
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>

      <ToastContainer />
    </CRow>
  )
}

export default UpdateAdvert
