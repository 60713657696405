import React, { Fragment, useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import { authProtectedRoutes } from './allRoutes'
import { ProtectedRoute, PublicRoute } from './AuthProtected'
import { CContainer } from '@coreui/react'
import { AppHeader, AppSidebar } from 'src/components'

export default function Index() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkUserToken = () => {
    const userToken = localStorage.getItem('auth-token')
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false)
    } else {
      setIsLoggedIn(true)
    }
  }

  useEffect(() => {
    checkUserToken()
    // eslint-disable-next-line
  }, [isLoggedIn])

  return (
    <Fragment>
      {isLoggedIn && <AppSidebar />}

      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        {isLoggedIn && <AppHeader />}

        <div className="body flex-grow-1 px-3">
          <CContainer lg>
            <Routes>
              {authProtectedRoutes
                .filter((c) => c.private)
                .map((route, id) => (
                  <Route
                    key={id}
                    path={route.path}
                    element={
                      <ProtectedRoute>
                        <route.component />
                      </ProtectedRoute>
                    }
                  />
                ))}

              {authProtectedRoutes
                .filter((c) => !c.private)
                .map((route, id) => (
                  <Route
                    key={id}
                    path={route.path}
                    element={
                      <PublicRoute>
                        <route.component />
                      </PublicRoute>
                    }
                  />
                ))}
            </Routes>
          </CContainer>
        </div>
      </div>
    </Fragment>
  )
}
